import React from 'react'
import './opening.css'
import './opening'
import { useState} from 'react'
// import Mui from '../mui_form/Mui'


const Opening = () => {

 
  const [showForm,setShowForm] = useState(false);
  const openForm = () => {
    setShowForm(!showForm);

  }
  return (
    <section className='opening' id='opening_container'>
      <div class="hero">
        <div className='desc'> 
                <h1>Welcome to <span>UTC Finance</span></h1>
                <h2> Smart Solutions for Brighter Finances.</h2>
              <div className="tag">
                    <span>
                    <span class="prevouse-word">
                    Providing best in class &nbsp;
                    </span>
                    <span class="holder">
                    <span class="item">Personal Loan</span>
                    <span class="item">Business Loan</span>
                    <span class="item">Home Loan</span>
                    <span class="item">Car Loan</span>
                    </span>
                    </span>
                </div>
        </div>
            <div className="btn_combo">
              <button className='opening_btn'>Talk to Advisor</button>
              <div>
              <button onClick={openForm} className='opening_btn'><p>Apply Now</p></button>
              {/* (showForm && <Mui/>) */}
              </div>
              
            </div>
            {/* <div className="user_number">
                <p>+91</p>
                <p>|</p>
                <div
                //  onSubmit={(e) => e.preventDefault()} 
                 className="number_form">
                <input type="text"  className='num_box' pattern='^[0-9]{3,10}$' minLength={10} maxLength={10} 
                // onChange={(e) => setNum(e.target.value)} 
                placeholder="Enter your Mobile Number" required="required"/>
                <button 
                // onclick={console.log(num)}
                className="btn mobile_number"><p>Apply Now</p></button>
                </div>
            </div> */}
  
      </div>
    </section>
  )
}

export default Opening